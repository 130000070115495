// INFO: purpose of this function to give you cart summary , it should return / total / subtotal / tax / discount
export const calculatePriceSummary = (data: any) => {
  const {
    isDigitalPaymentOn,
    products,
    flatDiscountRate,
    binDiscountAmount,
    voucherDiscountAmount,
    discountedCategories,
    voucherDetails,
    total,
    isProductLotOn = true
  } = data ?? {}

  // INFO: finding specific category of product which contains discount
  const getCategoryFlatDiscount = (product: any) => {
    return (
      discountedCategories?.find(
        (cat: any) => cat.bvid === product.categoryBvid
      )?.flatDiscount ?? 0
    )
  }

  // INFO: finding length of those taxes where discount is applicable
  const threeScheduleLength = products?.filter(
    (product: any) => !product?.taxClass?.thirdSchedule
  )?.length

  const getTax = () => {
    // INFO: this  function will apply discount on tax with priority or return 0
    const applyDiscountOnTax = (taxAmount: any, product: any) => {
      const bin = binDiscountAmount
      const voucher = voucherDiscountAmount
      const flatRate = flatDiscountRate
      const categoryFlatRate = getCategoryFlatDiscount(product)
      if (bin) {
        return bin / threeScheduleLength
      }

      if (voucher) {
        if (voucherDetails?.categoryXid === product?.categoryBvid) {
          if (voucher >= voucherDetails.maxDiscount) {
            return (product?.price / total) * voucherDetails.maxDiscount
          } else if (voucher < voucherDetails.maxDiscount) {
            if (voucherDetails?.discountType === "Percent") {
              return taxAmount * (voucherDetails?.discount / 100)
            } else if (voucherDetails?.discountType === "Flat") {
              return voucherDetails?.discount
            }
          }
        }

        return 0
      }

      if (flatRate) return taxAmount * (flatRate / 100)

      if (categoryFlatRate) return taxAmount * (categoryFlatRate / 100)

      return 0
    }

    // INFO: this function will return product price, if variant exists then variant price
    const bringProductPrice = (product: any) => {
      const selectedVariant: any = getSelectedVariants(
        product?.variants,
        product?.selectedVariant
      )

      let productPrice = 0
      if (product?.variants?.length)
        if (selectedVariant?.variantPrice)
          productPrice = selectedVariant?.variantPrice
        else {
          const lowestVariant = getLowestPriceVariant(product)

          productPrice = lowestVariant?.variantPrice
        }
      else if (product?.selectedVariant && !product?.variants?.length) {
        productPrice =
          product?.price + calculateOptionsPrice(product?.selectedVariant)
      } else {
        productPrice = product?.price
      }

      return product.quantity * productPrice
    }

    // INFO: this function will calculate tax on product price
    const calculateTax = (product: any, taxRate: any) => {
      const productPrice = bringProductPrice(product)
      return (productPrice * taxRate) / 100
    }

    // INFO: this function will calculate Inclusive tax on product price
    const calculateInclusiveTax = (product: any, taxRate: any) => {
      const productPrice = bringProductPrice(product)
      return productPrice - productPrice / (1 + taxRate / 100)
    }

    // INFO: adding tax amount with checking all conditions
    let taxInclusive = 0
    let totalTax = 0
    products?.forEach((product: any) => {
      const { digitalRate, cashRate, isInclusive, thirdSchedule } =
        product?.taxClass ?? {}
      const taxRate = isDigitalPaymentOn && digitalRate ? digitalRate : cashRate
      if (product?.selectedLot?.length && isProductLotOn && product?.taxClass) {
        const tax = calculateLotTax(product)
        totalTax += tax

        if (!thirdSchedule) {
          const discountOnTax = applyDiscountOnTax(tax, product)
          totalTax -= discountOnTax
        }
      } else if (product?.taxClass) {
        if (isInclusive) {
          const tax = calculateInclusiveTax(product, taxRate)
          taxInclusive += tax

          if (!thirdSchedule) {
            const discountOnTax = applyDiscountOnTax(tax, product)
            taxInclusive -= discountOnTax
          }
        } else {
          const tax = calculateTax(product, taxRate)
          totalTax += tax

          if (!thirdSchedule) {
            const discountOnTax = applyDiscountOnTax(tax, product)
            totalTax -= discountOnTax
          }
        }
      }
    })
    return { tax: totalTax || 0, taxInclusive: taxInclusive || 0 }
  }

  const getFullSummary = () => {
    const { tax, taxInclusive } = getTax()
    return {
      tax,
      taxInclusive
    }
  }

  return {
    getTax,
    getFullSummary
  }
}

const getLowestPriceVariant = (item: any) => {
  let lowestDiscountedPriceVariant: any = {}
  item.variants?.forEach((variant: any) => {
    const variantFinalPrice = variant.discountedPrice || variant.variantPrice
    const lowestVariantPrice =
      lowestDiscountedPriceVariant.discountedPrice ||
      lowestDiscountedPriceVariant.variantPrice
    lowestDiscountedPriceVariant =
      variantFinalPrice &&
      lowestVariantPrice &&
      variantFinalPrice >= lowestVariantPrice
        ? lowestDiscountedPriceVariant
        : variant
  })
  return lowestDiscountedPriceVariant.variantPrice
    ? lowestDiscountedPriceVariant
    : null
}

const getSelectedVariants = (allVariants: any, variantsList: any) => {
  return allVariants?.find((variant: any) =>
    variant.variantOptionValues.some((optionValue: any) =>
      variantsList.some((subItem: any) => subItem.bvid === optionValue.bvid)
    )
  )
}

export const calculateLotTax = (product: any) => {
  let total = 0
  product?.selectedLot?.forEach((lot: any) => {
    const qty = lot?.usedQty ? lot?.usedQty : 1
    const lotTotal = +lot?.gstValue * qty
    total = total + lotTotal
  })
  return total
}

const calculateOptionsPrice = (options: any) => {
  return options?.reduce((acc: any, option: any) => {
    return acc + option?.optionPrice
  }, 0)
}
