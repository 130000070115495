import { productType } from "constant/ProductConstant"

export const getProductPrice = (product: any, key = "price"): any => {
  return product?.discountedPrice && product?.discountedPrice < product?.[key]
    ? product?.discountedPrice
    : product?.[key]
}

export const checkQuantityDetail = (productContext: any) => {
  const products = [...productContext.selectedProductsList]
  const checkProduct = products.map((product) =>
    productContext.isQuantityInLimit(product, true)
  )
  return !checkProduct.includes(false)
}

export const isProductQuantityValid = (productContext: any) => {
  const products = [...productContext.selectedProductsList]
  let checkQuantity = true
  products.map((item) => {
    const { batchQuantity } = item
    const isBatchQuantityValid = batchQuantity
      ? item?.quantity <= batchQuantity
      : true
    item.quantityError = false
    if (!item?.quantity || item?.quantity === "0") {
      item.quantityError = true
      checkQuantity = false
    } else if (!isBatchQuantityValid) checkQuantity = false
    return item
  })
  productContext.setSelectedProductsList([...products])
  return checkQuantity
}

const addPriceKey = (variant: any) => {
  variant.price = variant.variantPrice
  return variant
}

export function lowestPriceVariant(item: any) {
  const variants = item.variants
  let lowestDiscountedPriceVariant: any = {}
  const activeVariants = variants?.filter((variant: any) => variant.isActive)
  const variantsToCheck = activeVariants?.length ? activeVariants : variants
  variantsToCheck?.forEach((variant: any) => {
    lowestDiscountedPriceVariant.price = variant.variantPrice
    const variantFinalPrice = variant.discountedPrice || variant.variantPrice
    const lowestVariantPrice =
      lowestDiscountedPriceVariant.discountedPrice ||
      lowestDiscountedPriceVariant.variantPrice
    lowestDiscountedPriceVariant =
      variantFinalPrice &&
      lowestVariantPrice &&
      variantFinalPrice >= lowestVariantPrice
        ? lowestDiscountedPriceVariant
        : variant
  })
  return lowestDiscountedPriceVariant.variantPrice
    ? addPriceKey(lowestDiscountedPriceVariant)
    : null
}

export function getPriceIfVariant(variant: any, product: any) {
  return variant ? variant : product
}

export function isDigital(type: any) {
  return type === productType.digital
}

export function getCategoryDiscount(product: any, categories: any) {
  const productCategory: any = categories.find(
    (category: { bvid: any }) => category.bvid === product.categoryBvid
  )
  const flatDiscount = productCategory?.flatDiscount || 0
  const flatDiscountApp = productCategory?.flatDiscountApp || 0

  return {
    flatDiscount,
    flatDiscountApp
  }
}

export const isProductAlreadyThere = (
  selectedProductsList: any,
  selectedProduct: any
) => {
  const products = [...selectedProductsList]
  const getProduct = products.find(
    (product) => selectedProduct?.bvid === product?.bvid
  )
  return getProduct
}
