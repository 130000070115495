import PosContext from "main/context/pos/PosContext"
import { PaymentMethodTypes } from "main/pos/constant/PosConstant"
import {
  calculateSubItemsTotal,
  convertSubVariantDetails
} from "main/pos/helpers/ProductHelpers"
import {
  getCurrentDateTime,
  getCurrentFormatedDateTime
} from "main/util/DateTimeHelper"
import { numberRoundUp } from "main/util/NumberHelper"
import { getProductPrice } from "main/util/ProductHelper"

type Product = {
  bvid: string
  name: string
  quantity: number
  pctCode: string
  selectedMainOptionXids: string[]
  price: number
  productIntegrations?: any[]
  selectedLot?: { gstValue: string; price: number; usedQty: number }[]
  subItems?: object[]
  taxClass?: Record<string, unknown>
}

type PosContext = {
  merchantProfileData?: {
    config?: {
      taxRate?: number
    }
  }
}

type ProductData = {
  posContext: PosContext
  configContext: { isRestaurantMode: () => boolean; isProductLotOn: boolean }
}

type FbrType = {
  InvoiceNumber: string
  POSID: string
  USIN: string
  DateTime: string
  Items: any[]
  TotalBillAmount: number
  TotalQuantity: number
  TotalSaleValue: number
  TotalTaxCharged: number
  PaymentMode: number
  InvoiceType: number
  Discount?: number
}

type PraType = FbrType

type SrbType = {
  posId: string
  ntn: string
  invoiceDateTime: string
  invoiceType: number
  invoiceID: string
  rateValue: number
  saleValue: number
  taxAmount: number
  TransType: string
}

type ObjType = {
  Fbr: FbrType
  Pra: PraType
  Srb: SrbType
}

const getProductTotal = (
  product: {
    quantity: string
    subItems: { price: number }
  },
  isRestaurantMode: () => boolean
) => {
  const productTotal =
    parseInt(product.quantity) * getProductPrice(product) || 0

  const subItemsTotal: number =
    calculateSubItemsTotal(product) * parseInt(product.quantity) || 0

  if (!isRestaurantMode())
    return (
      convertSubVariantDetails(product, product.subItems)?.price *
      parseInt(product.quantity)
    )
  return productTotal + subItemsTotal
}

const getTotal = (product: any, isRestaurantMode: () => boolean) => {
  if (product?.productLots?.length) {
    return numberRoundUp(product?.lotTotal - calculateSelectedLotsTax(product))
  } else if (product?.batches?.length)
    return numberRoundUp(product.batchedTotal)
  return numberRoundUp(getProductTotal(product, isRestaurantMode) || 0)
}

const calculateSelectedLotsTax = (product: Product): number => {
  if (!product?.selectedLot?.length) return 0

  return product?.selectedLot.reduce((acc, currentItem): any => {
    if (currentItem.usedQty) {
      return (acc += currentItem.usedQty * Number(currentItem.gstValue))
    }

    return (acc += Number(currentItem.gstValue))
  }, 0)
}

const calculateLotTaxRate = (
  product: Product,
  productPrice: number
): number => {
  return (calculateSelectedLotsTax(product) / productPrice) * 100
}

const calculateLotTotalAmount = (
  product: Product,
  productPrice: number
): number => {
  return Number(productPrice) + calculateSelectedLotsTax(product)
}

const setProductData = (
  product: Product,
  data: ProductData | any,
  paymentType: string
) => {
  const merchantProfileData = data.posContext.merchantProfileData
  const { isRestaurantMode, isProductLotOn } = data.configContext

  if (!merchantProfileData) {
    return null
  }

  const { bvid, name, quantity, pctCode, subItems } = product

  const productPrice: any = String(getTotal(product, isRestaurantMode)).replace(
    /,/g,
    ""
  )

  const taxRate: number | any =
    paymentType === PaymentMethodTypes.SwipeCard
      ? product?.taxClass?.cashRate
      : product?.taxClass?.digitalRate
  const taxCharged = quantity * productPrice * (taxRate / 100)
  const TotalAmount = quantity * productPrice + taxCharged

  const getPCTCode = product?.productIntegrations?.find(
    (item) => item?.integrationType === "Fbr" || item?.integrationType === "Pra"
  )?.integrationCode

  function restaurantDiscount() {
    return data?.orderData?.orderedProducts?.find((item: any) => {
      if (item.xid === bvid && item?.selectedMainOptionXids) {
        return (
          subItems?.length &&
          subItems
            ?.map((data: any) =>
              data.__typename === "MainOptionValue" ||
              data.__typename === "VariantOptionValue"
                ? item?.selectedMainOptionXids?.includes(data.bvid)
                : item?.selectedSubOptionXids?.includes(data.bvid)
            )
            .every((value: any) => value)
        )
      }
      if (item.xid === bvid && subItems?.length) {
        return (
          item?.selectedMainOptionXids &&
          subItems
            ?.map((data: any) =>
              data.__typename === "MainOptionValue" ||
              data.__typename === "VariantOptionValue"
                ? item?.selectedMainOptionXids?.includes(data.bvid)
                : item?.selectedSubOptionXids?.includes(data.bvid)
            )
            .every((value: any) => value)
        )
      }
      return item.xid === bvid
    })?.discountedAmount
  }

  function storeDiscount() {
    return data?.orderData?.orderedProducts?.find(
      (item: any) =>
        item.xid === bvid &&
        (subItems
          ?.map((data: any) =>
            item?.selectedOptions.split("/").includes(data?.title)
          )
          .every((value: any) => value) ??
          true)
    )?.discountedAmount
  }

  const discount = isRestaurantMode() ? restaurantDiscount() : storeDiscount()

  const finalProduct = {
    ItemCode: bvid,
    ItemName: name,
    Quantity: quantity,
    PCTCode: getPCTCode || pctCode,
    TaxRate: isProductLotOn
      ? calculateLotTaxRate(product, productPrice)
      : taxRate,
    SaleValue: Number(productPrice),
    TotalAmount: isProductLotOn
      ? calculateLotTotalAmount(product, productPrice)
      : TotalAmount,
    TaxCharged: isProductLotOn ? calculateSelectedLotsTax(product) : taxCharged,
    InvoiceType: 1,
    Discount: discount
  }

  return finalProduct
}

const calculateTotalSaleValue = (
  productList: Product[],
  isRestaurantMode: () => boolean
) => {
  return productList.reduce((acc, product) => {
    const productPrice: any = getTotal(product, isRestaurantMode)
    return acc + Number(productPrice)
  }, 0)
}

const calculateTotalQuantity = (productList: Product[]) => {
  return productList.reduce((acc, product) => acc + Number(product.quantity), 0)
}

const calculateProductLotsTotal = (productList: any[]) => {
  try {
    if (productList?.length) {
      const [product] = productList
      if (product?.selectedLot?.length) {
        const productLots: any[] = []
        productList &&
          productList.forEach((product) => {
            if (product?.selectedLot?.length)
              productLots.push(...product.selectedLot)
          })
        const totalAmount = productLots.reduce((accumulator, lot) => {
          const qty = lot.usedQty || 1
          const lotTotal = (+lot.price - +lot.gstValue) * qty
          return accumulator + lotTotal
        }, 0)

        return totalAmount || 0
      }
    }
  } catch (err) {
    console.info(err)
  }
}

const setInvoiceData = (data: any, posType: string) => {
  const { selectedProductsList } = data.productContext
  const {
    tax,
    discount,
    FBRPosId,
    fbrCharges,
    paymentMethod,
    merchantProfileData
  } = data.posContext
  const { isRestaurantMode } = data.configContext
  const totalSaleValue = data?.isProductLotOn
    ? calculateProductLotsTotal(selectedProductsList)
    : calculateTotalSaleValue(selectedProductsList, isRestaurantMode)
  const totalQuantity = calculateTotalQuantity(selectedProductsList)

  const taxInTotal = data?.isItMeriPharmacy()
    ? totalSaleValue - discount + tax
    : totalSaleValue - discount + tax

  const obj: ObjType = {
    Fbr: {
      InvoiceNumber: "",
      POSID: FBRPosId,
      USIN: data.orderNumber,
      DateTime: getCurrentDateTime(),
      Items: selectedProductsList.map((item: any) =>
        setProductData(item, data, paymentMethod)
      ),
      TotalBillAmount: Math.round(taxInTotal + fbrCharges),
      TotalQuantity: totalQuantity,
      TotalSaleValue: Math.round(totalSaleValue),
      TotalTaxCharged: Math.round(tax),
      PaymentMode: paymentMethod === PaymentMethodTypes.SwipeCard ? 2 : 1,
      InvoiceType: 1,
      Discount: Math.round(discount)
    },
    Pra: {
      InvoiceNumber: "",
      POSID: FBRPosId,
      USIN: data.orderNumber,
      DateTime: getCurrentDateTime(),
      Items: selectedProductsList.map((item: any) =>
        setProductData(item, data, paymentMethod)
      ),
      TotalBillAmount: taxInTotal,
      TotalQuantity: totalQuantity,
      TotalSaleValue: totalSaleValue,
      TotalTaxCharged: tax,
      PaymentMode: paymentMethod === PaymentMethodTypes.SwipeCard ? 2 : 1,
      InvoiceType: 1,
      Discount: discount
    },
    Srb: {
      posId: FBRPosId,
      ntn: merchantProfileData?.profile?.ntn,
      invoiceDateTime: getCurrentFormatedDateTime(),
      invoiceType: 1,
      invoiceID: `INV${Date.now()}`,
      rateValue: 13,
      saleValue: totalSaleValue + fbrCharges,
      taxAmount: tax,
      TransType: "Live:"
    }
  }

  return obj[posType as keyof ObjType]
}

export const postDataToSRB = (data: any, posType: string) => {
  const invoiceData = setInvoiceData(data, posType)
  const requestData = JSON.stringify(invoiceData)
  const xmlhttp = new XMLHttpRequest()

  return new Promise((resolve, reject) => {
    xmlhttp.onreadystatechange = function () {
      if (this.readyState == 4) {
        try {
          if (this.status === 200) {
            const srbRes = JSON.parse(this.responseText)
            if (srbRes.resCode === "00") {
              resolve(srbRes)
            } else {
              resolve(srbRes.err)
            }
          } else {
            resolve("Request failed with status: " + this.status)
          }
        } catch (error) {
          reject(error)
        }
      }
    }

    xmlhttp.open("POST", process.env.REACT_APP_SRB_URL as string, true)
    xmlhttp.setRequestHeader(
      "Content-type",
      "application/x-www-form-urlencoded"
    )
    xmlhttp.send(requestData)
  })
}

export async function postDataToFBR(data: any, posType: string) {
  if (posType === "Srb") {
    return postDataToSRB(data, posType)
  }

  const invoiceData = setInvoiceData(data, posType)

  try {
    const response = await fetch(process.env.REACT_APP_FBR_URL as string, {
      method: "POST",
      body: JSON.stringify(invoiceData),
      headers: {
        "Content-Type": "application/json"
      }
    })
    const data = await response.json()
    return data
  } catch (error) {
    return error
  }
}

export const revenuePartners: { [key: string]: string } = {
  Fbr: "Fbr",
  Pra: "Pra",
  Srb: "Srb"
}

export const getActiveRevenueIntegration = (merchantIntegrations: any[]) =>
  merchantIntegrations?.find(
    (item) => revenuePartners[item.partner] && item.status === "Active"
  )
